body {
  margin: 0;
  width: 100%;
  min-width: 320px;
  min-height: 100vh;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--principal-color);
  background: linear-gradient(
    180deg,
    var(--principal-color) 0%,
    var(--principal-color) 100%
  );
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#root {
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
:root {
  --accent-color: black;
  --principal-color: #3B6CB1;
}